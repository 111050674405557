import Vue from 'vue';
new Vue({
  el: '#header',
  data: {
    isShowNav: false,
    winW: 0,
    winH: 0
  },
  methods: {
    // Headerボタンを押下したときのハンドラ
    btHeaderClick: function () {
      this.isShowNav = !this.isShowNav;
    },
    onResize: function () {
      this.winW = window.innerWidth;
      this.winH = window.innerHeight;
      if (this.winW > 767) {
        this.isShowNav = true;
      } else {
        this.isShowNav = false;
      }
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize);
  }
});

Vue.component('move-top', {
  template: `<button class="bt-pagetop" v-on:click="moveToTop()"><img src="/common/images/pc_pagetop.png" alt=""></button>`,
  methods: {
    moveToTop: function () {
      const duration = 1000;
      const interval = 25;
      const step = -window.scrollY / Math.ceil(duration / interval);
      const timer = setInterval(() => {
        window.scrollBy(0, step);
        if (window.scrollY <= 0) {
          clearInterval(timer);
        }
      }, interval);
    }
  }
});

new Vue({
  el: '#pagetop'
});

let newsClass = document.getElementsByClassName('news');
if (newsClass.length !== 0) {
  let request = new XMLHttpRequest();
  request.open('HEAD', window.location.href, true);
  request.send();
  request.onreadystatechange = function() {
    if (this.readyState === 4) {
      var serverDate = new Date(request.getResponseHeader('Date'));
      let comparisonDate = getOfBeforeAfterDays(serverDate, -10);
      let newsDateElems = document.getElementsByClassName('news-section__date');
      for (let i = 0; i < newsDateElems.length; i++) {
        let articleY = +newsDateElems[i].innerText.split('.')[0];
        let articleM = (+newsDateElems[i].innerText.split('.')[1]) - 1;
        let articleD = +newsDateElems[i].innerText.split('.')[2];
        let articleDate = new Date();
        articleDate.setFullYear(articleY);
        articleDate.setMonth(articleM);
        articleDate.setDate(articleD);
        if (articleDate > comparisonDate) {
          newsDateElems[i].parentElement.parentElement.parentElement.parentElement.classList.add('new');
        }
      }
    }
  }
}

var getOfBeforeAfterDays = function(dateObj, number) {
    var result = false;
    if (dateObj && dateObj.getTime && number && String(number).match(/^-?[0-9]+$/)) {
        result = new Date(dateObj.getTime() + Number(number) * 24 * 60 * 60 * 1000);
    }
    return result;
};
